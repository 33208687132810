<template>
  <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
    <div class="row g-4">
      <div class="col-auto">
        <div class="avatar-lg">
          <div>
            <free-style-shimmer :is-loading="true" height="100px" width="100px" border-radius="50px" />
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col">
        <div class="p-2">
          <h3 class="text-white mb-3 text-capitalize">
            <free-style-shimmer :is-loading="true" height="20px" width="400px" />
          </h3>
          <p class="text-white-75">
            <free-style-shimmer :is-loading="true" height="11px" width="300px" />
          </p>
        </div>
      </div>
      <!--end col-->
      <div class="col-12 col-lg-auto order-last order-lg-0">
        <div class="row text text-white-50 text-center">
          <div class="col-lg-12 col-4">
            <div class="p-2">
              <center>
                <h4 class="text-white mb-1">
                  <free-style-shimmer :is-loading="true" height="50px" width="50px" />
                </h4>
              </center>
              <p class="fs-14 mb-0">
                <free-style-shimmer :is-loading="true" height="11px" width="100px" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="card pt-3 mt-3">
      <div class="card-body">
        <paragraph-shimmer :is-loading="true" :lines="20" :random-size="true" />
      </div>
    </div>
  </div>
</template>

<script>
  import { FreeStyleShimmer, ParagraphShimmer } from "vue3-shimmer";
export default {
    name: 'WithdrawShimmer',
  components: {
    FreeStyleShimmer,
    ParagraphShimmer
  },

}
</script>

<style>
</style>