<template>
    <div class="page-content">
        <div class="container-fluid">
            <div v-if="!voucherPurchase">
                <WithdrawShimmer />
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card mt-n4 mx-n4 mb-n5">
                            <div class="bg-soft-info">
                                <div class="card-body pb-4 mb-5">
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="row align-items-center">
                                                <div class="col-md-auto">
                                                    <div class="avatar-md mb-md-0 mb-4">
                                                        <div class="avatar-title bg-white rounded-circle">
                                                            <span class="ri ri-ticket-line text-primary fs-20"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end col-->
                                                <div class="col-md">
                                                    <h4 class="fw-semibold" id="ticket-title">
                                                        #{{ voucherPurchase["transaction"]["transaction_number"] }}
                                                    </h4>
                                                    <div class="hstack gap-3 flex-wrap">
                                                        <div class="text-muted">
                                                            <i class="ri-user-line align-bottom me-1"></i><span
                                                                id="ticket-client">{{
                                                                voucherPurchase["transaction"]["user"]["name"]
                                                                }}</span>
                                                        </div>
                                                        <div class="vr"></div>
                                                        <div class="text-muted">
                                                            Pengajuan :
                                                            <span class="fw-medium" id="create-date">{{
                                                            globalService.dateFormat2(voucherPurchase["transaction"]["crated_at"])
                                                            }}</span>
                                                        </div>
                                                        <div class="vr"></div>
                                                        <div class="text-muted">
                                                            Aksi :
                                                            <span class="fw-medium"
                                                                v-if="voucherPurchase['transaction']['validated_at']">{{
                                                                globalService.dateFormat2(voucherPurchase["transaction"]["validated_at"])
                                                                }}</span>
                                                            <span class="fw-medium" v-else>-</span>
                                                        </div>
                                                        <div class="vr"></div>
                                                        <div v-if="voucherPurchase['transaction']['status'] == 'Y'"
                                                            class="badge rounded-pill bg-info fs-12">
                                                            terverifikasi
                                                        </div>
                                                        <div v-if="voucherPurchase['transaction']['status'] == 'N'"
                                                            class="badge rounded-pill bg-danger fs-12">
                                                            tidak terverifikasi
                                                        </div>
                                                        <div v-if="voucherPurchase['transaction']['status'] == 'P'"
                                                            class="badge rounded-pill bg-warning fs-12">
                                                            pending
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end col-->
                                            </div>
                                            <!--end row-->
                                        </div>
                                    </div>
                                    <!--end row-->
                                </div>
                                <!-- end card body -->
                            </div>
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->

                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body p-4">
                                <h5 class="fs-14 mb-2">Detail Kupon Belanja</h5>
                                <div class="form-check card-radio mb-4">
                                    <label class="form-check-label" for="shippingMethod02">
                                        <span class="fs-20 float-end mt-2 text-wrap d-block fw-semibold">{{
                                        globalService.toIdr(voucherPurchase["fraction"])
                                        }}</span>
                                        <span class="fs-14 mb-1 text-wrap d-block">{{
                                        voucherPurchase["voucher_code"]
                                        }}</span>
                                        <span v-if="voucherPurchase['expired_date']"
                                            class="text-muted fw-normal text-wrap d-block mb-1">berlaku sampai {{
                                            globalService.dateFormat2(voucherPurchase['expired_date'])
                                            }}</span>
                                        <span v-if="voucherPurchase['printed'] != '1'"
                                            class="badge rounded-pill bg-danger fs-12">
                                            belum dicetak
                                        </span>
                                        <span v-else class="badge rounded-pill bg-success fs-12">
                                            sudah dicetak
                                        </span>
                                    </label>
                                </div>
                                <div class="float-end">
                                    <router-link :to="{ name: 'voucher-purchase' }" type="button"
                                        class="btn btn-warning waves-effect waves-light me-2"><i
                                            class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                        Kembali
                                    </router-link>
                                    <button type="button" @click="printVoucher()"
                                        v-if="voucherPurchase['transaction']['status'] == 'Y'"
                                        class="btn btn-success waves-effect waves-light me-2">
                                        <i class="bx bx-printer font-size-16 align-middle me-2"></i>
                                        Cetak Kupon
                                    </button>
                                    <button :disabled="send_unverifified" type="button"
                                        v-if="voucherPurchase['transaction']['status'] == 'P'"
                                        @click="confirmation('unverified')"
                                        class="btn btn-danger waves-effect waves-light me-2">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': send_unverifified, 'bx-x': !send_unverifified }"></i>
                                        Unverifikasi
                                    </button>
                                    <button :disabled="send_verifified" type="button"
                                        v-if="voucherPurchase['transaction']['status'] == 'P'"
                                        @click="confirmation('verified')"
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': send_verifified, 'bx-check': !send_verifified }"></i>
                                        Verifikasi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
        <!-- container-fluid -->
    </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import { endpoint, host } from "../../host";
import errorService from "../../services/error-service";
import globalService from "../../services/global-service";
import httpService from "../../services/http-service";
import numberService from "../../services/number-service";
import WithdrawShimmer from "../withdraw/shimmer/WithdrawShimmer.vue";
export default {
    components: {
        WithdrawShimmer
    },

    created() {
        this.id = this.$route.params.id;
        this.getVoucherPurchase();
    },

    data() {
        return {
            send_verifified: false,
            send_unverifified: false,
            id: null,
            voucherPurchase: null,
            moment: moment,
            numberService: numberService,
            globalService: globalService,
        };
    },

    methods: {
        async getVoucherPurchase() {
            try {
                let res = await httpService.get(
                    endpoint["voucher-purchase"] + `/show/${this.id}`
                );
                this.voucherPurchase = res.data.data;
            } catch (error) {
                errorService.displayError(error.response);
            }
        },

        confirmation(status) {
            Swal.fire({
                title: "Konfirmasi",
                text: "Apakah anda yakin ingin " + (status == 'verified' ? 'menyetujui kupon belanja ?' : 'menolak kupon belanja ?'),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#405189",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya, lanjutkan",
                cancelButtonText: "Tidak, batalkan",
                buttonsStyling: true,
            }).then((isConfirm) => {
                if (isConfirm.value === true) {
                    this.verified(status);
                }
            });
        },

        async verified(status) {
            try {
                status == 'verified' ? this.send_verifified = true : this.send_unverifified = true;
                let res = await httpService.post(
                    endpoint["voucher-purchase"] + `/validate/${this.voucherPurchase.id}`,
                    {
                        _method: 'put',
                        status: status == 'verified' ? 'Y' : 'N',
                    }
                );
                status == 'verified' ? this.send_verifified = false : this.send_unverifified = false;
                if (res.status == 200) {
                    this.getVoucherPurchase();
                    Swal.fire({
                        title: "Berhasil",
                        text: "kupon belanja " + (status == 'verified' ? 'terverifikasi' : 'tidak terverifikasi'),
                        icon: "success",
                    });
                }
            } catch (error) {
                status == 'verified' ? this.send_verifified = false : this.send_unverifified = false;
                errorService.displayError(error);
            }
        },

        printVoucher()
        {
            this.updatePrinted();
            window.open(`${host}/voucher-purchase/download/${this.voucherPurchase.voucher_code}`, '_blank');
        },

        async updatePrinted(){
            try {
                let res = await httpService.get(endpoint["voucher-purchase"] + `/printed/${this.voucherPurchase.id}`);
                if (res.status == 200) {
                    this.getVoucherPurchase();
                }
            } catch (error) {
                errorService.displayError(error);
            }
        }
    },
};
</script>

<style>
</style>